<template>
    <div class="white">
        <v-list color="primary">
            <v-list-item class="list-item">
                <v-list-item-avatar class="list-item-avatar">
                    <div class="empty-circle" @click="openPictureDialog" v-if="!object.image_url">
                        <span>Upload</span>
                    </div>
                    <v-img cover :src="object.image_url" v-if="object.image_url"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-h6 white--text" v-text="object.full_name"></v-list-item-title>
                    <v-list-item-subtitle class="white--text" v-text="object.email" v-if="object.email"></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-list :style="getStyle()">
            <v-subheader class="text-subtitle-1">Basic Information</v-subheader>
            <v-divider></v-divider>
            <v-list-item-group>
                <div :key="index" v-for="(item, index) in items">
                    <v-list-item :to="item.to" :ripple="false">
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
            </v-list-item-group>
            <v-list-item class="mt-12">
                <v-btn block large color="primary" class="elevation-0 white--text" :ripple="false" :loading="loading" @click="logout">Sign Out</v-btn>
            </v-list-item>
        </v-list>
        <my-profile-picture-dialog ref="pictureDialog" @created="handleCreatedProfilePicture"></my-profile-picture-dialog>
        <app-bottom-bar :active-tab="2"></app-bottom-bar>
    </div>

</template>


<script>

import { delay } from '@/utils/helper'
import { getLocalStorage, removeLocalStorage, removeSessionStorage, addLocalStorage } from '@/utils/auth'
import AppBottomBar from '../layout/AppBottomBar.vue'
import MyProfilePictureDialog from './profile/MyProfilePictureDialog.vue'

export default {
    name: 'MyAccount',
    components: {
        appBottomBar: AppBottomBar,
        myProfilePictureDialog: MyProfilePictureDialog,
    },
    data() {
        return {
            loading: false,
            object: {
                full_name: '',
                email: '',
                image_url: '',
            },
            items: [
                {
                    icon: 'mdi-account',
                    name: 'My Profile',
                    to: '/me/profile'
                },
                {
                    icon: 'mdi-format-list-bulleted-square',
                    name: 'My Worked History',
                    to: '/me/worked-history'
                }
            ]
        }
    },
    computed: {
        pictureDialog() {
            return this.$refs.pictureDialog
        }
    },
    mounted: function() {
        this.getObject()
    },
    methods: {
        logout: function() {
            this.loading = true
            this.$store.dispatch('auth/logout').then(() => {
				this.$router.push({ path: '/login' })
                delay(() => {
                    removeLocalStorage('blink-data')
                    removeSessionStorage('blink-data')
                }, 2000)
			}).catch(() => {
                this.loading = false
			})
		},
        getObject: function(){
            if(this.$store.getters.auth.full_name){
                this.object = this.$store.getters.auth
            }else{
                let user = getLocalStorage('blink-data', 'user')
                if(user){
                    this.object = user
                }
            }
        },
        getStyle: function() {
            const minHeight = (window.innerHeight - 172).toString() + "px";
            return {"min-height": minHeight}
        },
        openPictureDialog: function() {
            this.pictureDialog.open()
        },
        handleCreatedProfilePicture: function(item) {
            this.$set(this.object, 'image_url', item.image_url)
            var user = getLocalStorage('blink-data', 'user')
            if(user) {
                user['image_url'] = item.image_url
                addLocalStorage('blink-data', 'user', user)
            }
        }
    }

}

</script>


<style scoped>

.list-item {
    height: 100px !important;
}

.list-item-avatar {
    height: 56px !important;
    width: 56px !important;
}

.empty-circle {
    width: 56px !important;
    height: 56px !important;
    background-color: white !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.empty-circle span {
    font-size: 12px !important;
}

</style>