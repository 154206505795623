<template>
    <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition" v-model="dialog">
        <v-card :style="getStyle(56)" class="elevation-0">
            <v-toolbar color="primary">
                <v-btn icon @click="close" class="white--text">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title class="white--text">Upload Profile Picture</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text :style="getStyle(56)" class="preview" v-if="object.image">
                <p class="d-flex justify-center"><v-img contain max-width="60%" class="mt-10" :src="object.image"></v-img></p>
                <v-spacer></v-spacer>
                <p class="d-flex justify-space-between">
                    <v-btn outlined class="elevation-0" color="primary" :disabled="loading" :ripple="false" @click="reset">Retake</v-btn>
                    <v-btn class="elevation-0" color="primary" :loading="loading" :ripple="false" @click="save">Upload</v-btn>
                </p>
            </v-card-text>
            <v-layout align-center justify-center :style="getStyle(56, '#f7f8fa')" v-if="!object.image">
                <van-uploader max-count="1" :preview-image="false" :after-read="afterRead" v-model="object.images" />
            </v-layout>
        </v-card>
    </v-dialog>
</template>


<script>

export default {
    name: 'MyProfilePictureDialog',
    data() {
        return {
            loading: false,
            dialog: false,
            object: {
                image: '',
                images: []
            }
        }
    },
    methods: {
        open: function() {
            this.dialog = true
            this.reset()
        },
        close: function() {
            this.dialog = false
        },
        reset: function() {
            this.object = { images: [], image: '' }
        },
        getStyle: function(bottomHeight=0, backgroundColor='') {
            const minHeight = (window.innerHeight - bottomHeight).toString() + 'px';
            return {'min-height': minHeight, 'background-color': backgroundColor}
        },
        afterRead(file) {
            const maxSize = 800
            let canvas = document.createElement('canvas')
            let image = new Image()
            image.src = file.content
            image.onload = () => {
                var width = image.width, height = image.height
                if(width > height && width > maxSize){
                    height *= maxSize / width
                    width = maxSize
                }else if(width <= height && height > maxSize){
                    width *= maxSize / height;
                    height = maxSize
                }

                canvas.width = width
                canvas.height = height
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                this.object.image = canvas.toDataURL('image/jpeg')
            }
        },
        save: function() {
            if(this.object.image) {
                this.loading = true
                this.$store.dispatch('mobile/account/createProfilePicture', { image: this.object.image }).then((response) => {
                    this.loading = false
                    this.close()
                    this.$emit('created', { image_url: response.data.object.image_url })
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false;
                })
            }

        }
    },
};
</script>

<style scoped>

.preview {
    display: flex !important;
    flex-direction: column;
}

/deep/ .van-uploader__upload-icon {
    font-size: 90px !important;
}

</style>